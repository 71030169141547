<template>
  <div>
    <ca-header
      :heading="workshopName"
      :loading="loading"
    >
      <template #buttonSpace>
        <router-link
          v-if="me.role === 10"
          class="btn btn-secondary text-primary mr-2"
          tag="button"
          :to="{name: 'WorkshopEdit', params: {workshopId: workshop._id}}"
        >
          <i class="far fa-pen" /> Bearbeiten
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card class="mb-3">
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    Partnerdaten
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <h5>Allgemein</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Name
                </div>
                <div class="col-12 col-md-8">
                  {{ workshopName }}
                </div>
                <div class="col-12 col-md-4">
                  Straße
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.STR }}
                </div>
                <div class="col-12 col-md-4">
                  PLZ, Ort
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.PLZ }} {{ workshop.address.ORT }}
                </div>
                <div class="col-12 col-md-4">
                  Land
                </div>
                <div class="col-12 col-md-8">
                  {{ country }}
                </div>
                <hr>
                <div class="col-12 col-md-4">
                  Betreuer
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.BETREUER1 }}
                </div>
                <div class="col-12 col-md-4">
                  Vermittler
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.VMT }}
                </div>
              </div>
              <template v-if="workshop.address.BEV1">
                <h5>Spezifischer Empfänger (zu Händen)</h5>
                <div class="row mb-3">
                  <div class="col-12 col-md-4">
                    Name
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.BEV1 }} {{ workshop.address.BEZ }}
                  </div>
                  <div class="col-12 col-md-4">
                    Individualanrede
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.SGE }}
                  </div>
                  <div class="col-12 col-md-4">
                    Bemerkung
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.BEMERKUNG01 }}
                  </div>
                </div>
              </template>
              <h5>Kontakt</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Partner-Nr.
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.PARTNERNR }}
                </div>
                <hr>
                <div class="col-12 col-md-4">
                  Bevorzugte Kontaktart
                </div>
                <div class="col-12 col-md-8">
                  <!-- {{ contactType[workshop.address.contact.Kontaktart].human }} -->
                  {{ workshop.address.contact.Kontaktart }}
                </div>
                <div class="col-12 col-md-4">
                  Festnetztelefon
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.TEF }}
                </div>
                <div class="col-12 col-md-4">
                  Mobiltelefon
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.MOB }}
                </div>
                <div class="col-12 col-md-4">
                  Telefax
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.FAX }}
                </div>
                <div class="col-12 col-md-4">
                  E-Mail
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.MAIL }}
                </div>
                <div class="col-12 col-md-4">
                  Internet
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.URL }}
                </div>
                <hr>
                <div class="col-12 col-md-4">
                  Verbindung
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address.contact.GKC }}
                </div>
              </div>
              <template v-if="privateAddress">
                <h5>Privatkontakt</h5>
                <div class="row mb-3">
                  <div class="col-12 col-md-4">
                    Festnetztelefon
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.contact.TEL }}
                  </div>
                  <div class="col-12 col-md-4">
                    Mobiltelefon
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.contact.MOBIL_PRIVAT }}
                  </div>
                  <div class="col-12 col-md-4">
                    Telefax
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.contact.FAX_PRIVAT }}
                  </div>
                  <div class="col-12 col-md-4">
                    E-Mail
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.contact.MAIL_PRIVAT }}
                  </div>
                  <div class="col-12 col-md-4">
                    Internet
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.contact.URL_PRIVAT }}
                  </div>
                </div>
              </template>
            </template>
          </ca-card>
          <ca-card
            v-if="workshop.address2.NAM2"
            class="mb-3"
          >
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    2. Adresse
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <h5>Allgemein</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Name
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address2.VOR2 }} {{ workshop.address2.NAM2 }} {{ workshop.address2.NA22 }}
                </div>
                <div class="col-12 col-md-4">
                  Straße
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address2.STR2 }}
                </div>
                <div class="col-12 col-md-4">
                  PLZ, Ort
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address2.PLZ2 }} {{ workshop.address2.ORT2 }}
                </div>
                <div class="col-12 col-md-4">
                  Land
                </div>
                <div class="col-12 col-md-8">
                  {{ country2 }}
                </div>
              </div>
              <h5>Kontakt</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Festnetztelefon
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address2.contact.TEF2 }}
                </div>
                <div class="col-12 col-md-4">
                  Mobiltelefon
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address2.contact.MOB2 }}
                </div>
                <div class="col-12 col-md-4">
                  Telefax
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address2.contact.FAX2 }}
                </div>
                <div class="col-12 col-md-4">
                  E-Mail
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.address2.contact.MAIL2 }}
                </div>
              </div>
              <template v-if="privateAddressSecond">
                <h5>Privatkontakt</h5>
                <div class="row mb-3">
                  <div class="col-12 col-md-4">
                    Festnetztelefon
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address2.contact.TEL2 }}
                  </div>
                  <div class="col-12 col-md-4">
                    Mobiltelefon
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address2.contact.MOBIL2_PRIVAT }}
                  </div>
                  <div class="col-12 col-md-4">
                    Telefax
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address2.contact.FAX2_PRIVAT }}
                  </div>
                  <div class="col-12 col-md-4">
                    E-Mail
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address2.contact.MAIL2_PRIVAT }}
                  </div>
                </div>
              </template>
            </template>
          </ca-card>
          <ca-card
            v-if="bankDetailsList.length > 0 && bankDetailsList[0].IBAN"
            class="mb-3"
          >
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    Bankdaten
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <div
                v-for="bank in bankDetailsList"
                :key="bank._id"
              >
                <h5>{{ bank.VWZ }}</h5>
                <div class="row mb-3">
                  <div class="col-12 col-md-4">
                    Bankname
                  </div>
                  <div class="col-12 col-md-8">
                    {{ bank.BLZTEXT }}
                  </div>
                  <div class="col-12 col-md-4">
                    IBAN
                  </div>
                  <div class="col-12 col-md-8">
                    {{ bank.IBAN }}
                  </div>
                </div>
                <template v-if="bank.diffHolder.ABWNAM">
                  <h5>Abweichender Kontoinhaber</h5>
                  <div class="row mb-3">
                    <div class="col-12 col-md-4">
                      Name
                    </div>
                    <div class="col-12 col-md-8">
                      {{ bank.diffHolder.ABWVOR }} {{ bank.diffHolder.ABWNAM }}
                    </div>
                    <div class="col-12 col-md-4">
                      Straße, Nr.
                    </div>
                    <div class="col-12 col-md-8">
                      {{ bank.diffHolder.ABWSTR }}
                    </div>
                    <div class="col-12 col-md-4">
                      PLZ, Ort
                    </div>
                    <div class="col-12 col-md-8">
                      {{ bank.diffHolder.ABWPLZ }} {{ bank.diffHolder.ABWORT }}
                    </div>
                  </div>
                </template>
                <template v-if="Object.keys(bank.sepa).length !== 0">
                  <h5>SEPA Mandat</h5>
                  <div class="row mb-3">
                    <div class="col-12 col-md-4">
                      SEPA-Mandats-Nr.
                    </div>
                    <div class="col-12 col-md-8">
                      {{ bank.sepa.SEPAMANDAT }}
                    </div>
                    <div class="col-12 col-md-4">
                      Mandat angefordert / erhalten
                    </div>
                    <div class="col-12 col-md-8">
                      {{ formatDate(bank.sepa.MANDANF) }}, {{ formatDate(bank.sepa.MANDERH) }}
                    </div>
                    <div class="col-12 col-md-4">
                      Lastschrift-Art / -Status
                    </div>
                    <div class="col-12 col-md-8">
                      {{ bank.sepa.LSTART }}, {{ bank.sepa.LSTSTATUS }}
                    </div>
                    <div class="col-12 col-md-4">
                      Letzte Abbuchung am
                    </div>
                    <div class="col-12 col-md-8">
                      {{ formatDate(bank.sepa.MANDATAKTV) }}
                    </div>
                    <template v-if="bank.sepa.MANDATENDE">
                      <div class="col-12 col-md-4">
                        Mandat aufgehoben am
                      </div>
                      <div class="col-12 col-md-8">
                        {{ formatDate(bank.sepa.MANDATENDE) }}
                      </div>
                      <div class="col-12 col-md-4">
                        Aufhebungsgrund
                      </div>
                      <div class="col-12 col-md-8">
                        {{ bank.sepa.ENDEGRUND }}
                      </div>
                    </template>
                  </div>
                </template>
                <hr v-if="bankDetailsList.length > 1">
              </div>
            </template>
          </ca-card>
          <ca-card
            v-if="workshop.address2"
            class="mb-3"
          >
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    Information
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Partnerfoto
                </div>
                <div class="col-12 col-md-8">
                  <img
                    v-if="logo"
                    :src="logo.downloadUrl"
                    class="logo"
                  >
                </div>
                <div class="col-12 col-md-4">
                  Zielgruppe
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.information.ZIELGR }}
                </div>
                <div class="col-12 col-md-4">
                  Aktionen
                </div>
                <div class="col-12 col-md-8">
                  {{ workshop.information.AKTIONEN }}
                </div>
              </div>
            </template>
          </ca-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import CaHeader from '@/components/Layout/Header'
import contactType from '@/resources/enums/contactType'
import moment from 'moment'
import feathers from '@/api'

export default {
  name: 'WorkshopDetail',
  components: {
    CaCard,
    CaHeader
  },
  data () {
    return {
      workshop: null,
      bankDetailsList: [],
      workshopName: null,
      country: '',
      country2: '',
      loading: false,
      contactType,
      logo: null
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    privateAddress () {
      if (this.workshop.address.contact) {
        const contact = this.workshop.address.contact
        return contact.TEL || contact.MOBIL_PRIVAT || contact.FAX_PRIVAT || contact.MAIL_PRIVAT || contact.URL_PRIVAT
      } else {
        return false
      }
    },
    privateAddressSecond () {
      if (this.workshop.address2.contact) {
        const contact = this.workshop.address2.contact
        return contact.TEL2 || contact.MOBIL2_PRIVAT || contact.FAX2_PRIVAT || contact.MAIL2_PRIVAT
      } else {
        return false
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        const workshop = await this.$store.dispatch('workshops/get', this.$route.params.workshopId)
        const bankDetailsList = await this.getBankAccounts(workshop)
        this.workshop = workshop.clone()
        this.bankDetailsList = [...bankDetailsList]
        if (this.workshop.information.PARTNERFOTO) {
          this.logo = await feathers.service('file').get(this.workshop.information.PARTNERFOTO)
        }
        this.country = (await this.$store.dispatch('countries/find', { query: { char: this.workshop.address.NAT.char } })).data[0].name
        if (this.workshop.address2.NAT2.char) {
          this.country2 = (await this.$store.dispatch('countries/find', { query: { char: this.workshop.address2.NAT2.char } })).data[0].name
        }
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
        this.workshopName = this.workshop.address.NAME.VOR ? `${this.workshop.address.NAME.VOR} ${this.workshop.address.NAME.NAM}` : `${this.workshop.address.NAME.NAM}`
      }
    },
    async getBankAccounts (workshop) {
      const bankDetailsList = []
      if (workshop.information.bankId.length > 0) {
        for (const id of workshop.information.bankId) {
          bankDetailsList.push(await this.$store.dispatch('bank-account/get', id))
        }
      }
      return bankDetailsList
    },
    formatDate (dateString) {
      return dateString ? moment(dateString).format('DD.MM.YYYY') : null
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    max-width:  320px;
    max-height: 220px;
    object-fit: cover;
  }
</style>
